import styled from "@emotion/styled";
import { colors, media } from "../../BaseStyles";


export const Container = styled.div`
  width: 100vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 20px;
`

export const Group = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: ${colors.primaryInverted};
  color: ${colors.primary};

  ${media.largeWidth} {
    flex-direction: row;
    flex-wrap: wrap;
  }

  ${media.darkMode} {
    background: ${colors.primary};
    color: ${colors.primaryInverted};
  } 
`
