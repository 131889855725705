import styled from "@emotion/styled";
import { colors, media } from "../../../BaseStyles";
import SearchGrayImage from "../../../images/searchGray.png"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 100vw;

`
export const SuggestionsList = styled.div`
  width: 100vw;

  overflow-y: scroll;
`

export const Row = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`

export const Suggestion = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  width: 100vw;

  img {
    width: ${props => props.base64 ? "30" : "26"}px;
    height: ${props => props.base64 ? "30" : "26"}px;
    padding: ${props => props.base64 ? "10" : "12"}px;

    object-fit: contain;
  }

  p {
    margin: 0;

    font-size: 18px;
  }
`

export const HintImage = styled.img`
  align-self: center;

  width: 75px;
  height: 75px;
  padding: 20px;

  object-fit: contain;
  content: url(${SearchGrayImage});
`

export const HintText = styled.div`
  align-self: center;
  padding: 20px;

  font-size: 20px;
  text-align: center;
  color: ${colors.secondary};
`

export const CloseButton = styled.button`
  align-self: center;

  padding: 10px !important;

  color: ${colors.secondary};
  background: none !important;
  border: none;

  text-decoration: underline;
  cursor: pointer;

  font-size: 20px;
  font-weight: bold;
`;