import styled from "@emotion/styled";
import { media } from "../../BaseStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

export const MainPanel = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100vw;
  padding-top: 75px;
  padding-bottom: 75px;

  text-align: center;
`

export const AppStoreBadge = styled.img`
  height: 60px;

  object-fit: contain;
  content: url('/locales/${(props) => (props.lang ? props.lang : "en")}/images/AppStoreBadgeLight.svg');

  cursor: pointer;

  ${media.smallWidth} {
    height: 50px;
  }

  ${media.smallHeight} {
    height: 50px;
  }

  ${media.darkMode} {
    content: url('/locales/${(props) => (props.lang ? props.lang : "en")}/images/AppStoreBadgeDark.svg');
  }
`