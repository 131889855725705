import { MyInput } from "./styled"

const TextField = ({ placeholder, text, onTextChange, onFocusChange, onEnterKeyPressed, inputRef }) => {
  const onKeyDown = (event) => {
    if (event.keyCode === 13) {
      onEnterKeyPressed()
    }
  }

  const onFocus = () => {
    onFocusChange(true)
  }

  const onBlur = () => {
    onFocusChange(false)
  }

  return (
    <MyInput 
      type="text" 
      ref={inputRef}
      placeholder={placeholder} 
      onChange={onTextChange} 
      onKeyDown={onKeyDown}
      onFocus={onFocus}
      onBlur={onBlur}
      value={text} />
  )
}

export default TextField