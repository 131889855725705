import { useEffect, useState } from "react"

const ColorScheme = {
  light: "light",
  dark: "dark"
}

const useColorScheme = () => {
  const [colorScheme, setColorScheme] = useState(ColorScheme.light)
  useEffect(() => {
    const darkMediaQuery = window.matchMedia("(prefers-color-scheme: dark)")
    setColorScheme(darkMediaQuery.matches ? ColorScheme.dark : ColorScheme.light)
    darkMediaQuery.addEventListener("change", (event) => {
      setColorScheme(event.matches ? ColorScheme.dark : ColorScheme.light)
    })
  }, [])
  return {
    darkMode: colorScheme === ColorScheme.dark,
    lightMode: colorScheme === ColorScheme.light,
  }
}

export default useColorScheme