import { Container, Group } from "./styled"
import { useTranslation } from 'react-i18next'
import useWindowDimensions, { BreakPoint } from "../../utils/useWindowDimensions"
import Panel from "./Panel/Panel"
import { HorizontalLine } from "../../BaseStyles"
import Selector from "../Selector/Selector"
import useDeviceDetect from "../../utils/useDeviceDetect"

const PanelsGroup = () => {
  const { device, setDevice } = useDeviceDetect()
  const { t } = useTranslation()
  const { widthBp } = useWindowDimensions()

  const shouldAlternate = (index) => {
    if (widthBp === BreakPoint.large) {
      const alternateLeftBox = ((index - 2) % 4 === 0)
      const alternateRightBox = ((index - 1) % 4 === 0)
      return alternateLeftBox || alternateRightBox
    } else {
      return index % 2 !== 0
    }
  }

  const handleSelector = () => {
    setDevice(device === 'iPad' ? 'iPhone' : 'iPad')
  }

  return (
    <Container>
      <Selector
        firstText={t`panelGroup.iphone`} 
        secondText={t`panelGroup.ipad`} 
        firstSelected={device !== 'iPad'} 
        onClick={handleSelector} />
      <p/>
      <HorizontalLine />
      <Group>
        { t(`homepage.panels`).map((panel, index) => (
          <Panel key={index} alternate={shouldAlternate(index)} iPad={device === 'iPad'} title={panel.title} text={panel.text} image={panel.image} />
        ))}
      </Group>
      <HorizontalLine />
    </Container>
  )
}

export default PanelsGroup