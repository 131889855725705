import { useTranslation } from "react-i18next"
import { Container } from "./styles"

const SupportPage = () => {
  const { t } = useTranslation()

  const infoMailto = `mailto:${t`infoEmail`}`
  const supportMailto = `mailto:${t`supportEmail`}`

  return (
    <Container>
      <h2>{t`support.title`}</h2>
      <p>{t`support.info`}</p>
      <h3><a href={infoMailto}>{t`infoEmail`}</a></h3>
      <p>{t`support.support`}</p>
      <h3><a href={supportMailto}>{t`supportEmail`}</a></h3>
    </Container>
  )
}

export default SupportPage