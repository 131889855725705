import { Outlet } from "react-router-dom";
import { useTranslation } from 'react-i18next'
import { isMobile } from 'react-device-detect';
import { Container, Footer, MenuItem } from "./styled";
import { useEffect, useState } from "react";

const Layout = () => {
  const { t } = useTranslation()
  const [showFooter, setShowFooter] = useState(true)

  useEffect(() => {
    // generic method when focus in/out to hide/show footer on toggling virtual keyboard
    if (isMobile) {
      let textFields = document.querySelectorAll("input[type=text]")
      textFields.forEach(input => {
        input.addEventListener('focusin', () => setShowFooter(false))
        input.addEventListener('focusout', () => setShowFooter(true))
      })
    }
  }, [])

  const scrollToTop = () => {
    window.scrollTo(0, 0)
  }

  return (
    <Container>
      <Outlet />
      { showFooter &&
        <Footer>
            <MenuItem to="/" onClick={scrollToTop}>{t`layout.homepage`}</MenuItem>
            <MenuItem to="/releasenotes" onClick={scrollToTop}>{t`layout.releaseNotes`}</MenuItem>
            <MenuItem to="/support" onClick={scrollToTop}>{t`layout.support`}</MenuItem>
            <MenuItem to="/privacy" onClick={scrollToTop}>{t`layout.privacy`}</MenuItem>
        </Footer>
      }
    </Container>
  )
};

export default Layout;