import { useTranslation } from "react-i18next"
import { Container } from "./styles"

const PrivacyPage = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <h2>{t`privacyPolicy.title`}</h2>

      { t`privacyPolicy.paragraphs`.map((paragraph, index) => (
        <p key={index}>{paragraph}</p>
      ))}
    </Container>
  )
}

export default PrivacyPage