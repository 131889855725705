import styled from "@emotion/styled";
import { colors, media } from "../../BaseStyles";

export const MyButton = styled.button`
  box-sizing: border-box;
  min-width: 80px;
  height: 40px;
  margin: 0;
  padding-left: 10dp;
  padding-right: 10dp;

  background: ${({alternate}) => (alternate ? colors.primaryInverted : colors.primary)};
  color: ${({alternate}) => (alternate ? colors.primary : colors.primaryInverted)};
  border: 0;
  border-radius: 0;

  font-size: 20px;

  ${media.darkMode} {
    background: ${({alternate}) => (alternate ? colors.primary : colors.primaryInverted)};
    color: ${({alternate}) => (alternate ? colors.primaryInverted : colors.primary)};
  }
`