import styled from "@emotion/styled";
import { colors } from "../../BaseStyles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 80%;
    min-height: 600px;

    a {
        color: ${colors.secondary};
        text-decoration: underline;
    }
`