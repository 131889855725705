import { Choice, Container } from "./styled"

const Selector = ({firstText, secondText, firstSelected = true, onClick}) => {
  return (
    <Container>
      <Choice 
        selected={firstSelected} 
        onClick={onClick}>
          {firstText}
      </Choice>
      <Choice 
        selected={!firstSelected}  
        onClick={onClick}>
          {secondText}
      </Choice>
    </Container>
  )
}

export default Selector