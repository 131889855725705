import styled from "@emotion/styled";
import { colors, media } from "../../BaseStyles";

export const Container = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  min-width: 160px;
  margin: 0;
  padding: 0;

  background: ${colors.primaryInverted};
  border: 1px solid ${colors.primary};
  border-radius: 0;

  font-size: 20px;

  cursor: pointer;

  ${media.darkMode} {
    background: ${colors.primary};
    border: 1px solid ${colors.primaryInverted};
  }
`

export const Choice = styled.div`
  min-width: 80px;
  height: 38px;
  margin: 0;

  text-align: middle;
  line-height: 38px;

  background: ${({selected}) => (selected ? colors.primary : colors.primaryInverted)};
  color: ${({selected}) => (selected ? colors.primaryInverted : colors.primary)};

  font-weight: ${({selected}) => (selected ? "bold" : "normal")};

  ${media.darkMode} {
    background: ${({selected}) => (selected ? colors.primaryInverted : colors.primary)};
    color: ${({selected}) => (selected ? colors.primary : colors.primaryInverted)};
  }
`