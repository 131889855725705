import styled from "@emotion/styled";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 80%;
    min-height: 600px;

    h2 {
        align-self: center;
    }
`