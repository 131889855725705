import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Layout from "./components/Layout/Layout";
import HomePageBrowser from "./pages/HomePageBrowser/HomePageBrowser";
import HomePageMonoApp from "./pages/HomePageMonoApp/HomePageMonoApp"
import PrivacyPage from "./pages/PrivacyPage/PrivacyPage";
import NotFoundPage from "./pages/NotFoundPage/NotFoundPage";
import { useTranslation } from 'react-i18next'
import { insideMonoApp } from "./utils/MonoAppBridge"
import ReleaseNotesPage from "./pages/ReleaseNotesPage/ReleaseNotesPage";
import SupportPage from "./pages/SupportPage/SupportPage";
import { useEffect } from "react";
import packageJson from "../package.json"

const App = () => {
  const { t } = useTranslation()

  useEffect(() => {
    console.log(`Website version ${packageJson.version}`)
  }, [])

  const HomePage = insideMonoApp() ? HomePageMonoApp : HomePageBrowser

  return (
    <HelmetProvider>
      <Helmet>
        <title>{t`appTitle`}</title>
        <meta 
          name="viewport" 
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no, viewport-fit=cover" /> 
        <meta
          name="description"
          content={t`appDescription`}
        />
      </Helmet>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<HomePage />} />
            <Route path="releasenotes" element={<ReleaseNotesPage />} />
            <Route path="support" element={<SupportPage />} />
            <Route path="privacy" element={<PrivacyPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
