import styled from "@emotion/styled";
import { colors, media } from "../../../BaseStyles";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: flex-start;

  width: 100vw;
  height: 450px;

  padding-top: 20px;
  padding-bottom: 20px;

  background: ${(props) => (props.alternate ? colors.primary : colors.primaryInverted)};


  ${media.smallWidth} {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    height: inherit;
    min-height: 450px;
  }

  ${media.largeWidth} {
    width: 50vw;
  }

  ${media.smallHeight} {
    height: 250px;
  }

  ${media.darkMode} {
    background: ${(props) => (props.alternate ? colors.primaryInverted : colors.primary)};
    color: ${(props) => (props.alternate ? colors.primary : colors.primaryInverted)};
  }
`

export const TextBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 60%;
  margin-left: 50px;
  margin-right: 50px;

  font-size: 30px;
  color: ${(props) => (props.alternate ? colors.primaryInverted : colors.primary)};

  ${media.darkMode} {
    color: ${(props) => (props.alternate ? colors.primary : colors.primaryInverted)};
  }

  ${media.smallWidth} {
    align-items: center;
    
    width: 90vw;
    margin: 0;

    text-align: center;
    font-size: 16px;
  }

  ${media.mediumWidth} {
    font-size: 24px;
  }

  ${media.smallHeight} {
    font-size: 16px;
  }

  h3 {
    ${media.smallWidth} {
      margin: 0;
      padding: 0;
    }
  }
`

export const ImageBox = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;

  width: 40%;
  height: inherit;
  margin-right: 50px;

  ${media.smallWidth} {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 0;
  }

  img {
    height: inherit;
    object-fit: contain;
    background: ${colors.primary};
    padding: ${(props) => (props.iPad ? "10px" : "3px")};;
    border: 1px solid ${(props) => (props.alternate ? colors.primaryInverted : colors.primary)};
    border-radius: ${(props) => (props.iPad ? "12px" : "30px")};

    ${media.darkMode} {
      color: ${(props) => (props.alternate ? colors.primary : colors.primaryInverted)};
    }

    ${media.smallWidth} {
      width: 80vw;
    }
  }
`