import { Container, TextBox, ImageBox } from "./styled"

const Panel = ({alternate, iPad, title, text, image}) => {
  return (
    <Container alternate={alternate}>
      <TextBox alternate={alternate}>
        <h3>{title}</h3>
        <p>{text}</p>
      </TextBox>
      <ImageBox alternate={alternate} iPad={iPad}>
        <img src={iPad ? image.iPad : image.iPhone} alt=""/>
      </ImageBox>
    </Container>
  )
}

export default Panel;