import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { colors, media } from "../../BaseStyles"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  width: 100vw;

  color: ${colors.primary};
  background-color: ${colors.primaryInverted};

  ${media.darkMode} {
    color: ${colors.primaryInverted};
    background-color: ${colors.primary};
  }
`

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flexShrink: 0;

  width: 100vw;
  padding-top: 25px;
  padding-bottom: 25px;

  background: ${colors.primaryInverted};

  ${media.darkMode} {
    background: ${colors.primary};
  }

  ${media.smallWidth} {
    flex-direction: column;
    justify-content: flex-start;
  }
`

export const MenuItem = styled(Link)`
  color: ${colors.primary};
  text-underline-color: ${colors.primary};

  font-weight: bold;

  ${media.darkMode} {
    color: ${colors.primaryInverted};
    text-underline-color: ${colors.primaryInverted};
  }

  ${media.smallWidth} {
    padding: 6px;
  }
`