import { useTranslation } from "react-i18next"
import { Container, VersionBox } from "./styles"


const ReleaseNotesPage = () => {
  const { t } = useTranslation()

  return (
    <Container>
      <h2>{t`releaseNotes.title`}</h2>
      { t`releaseNotes.versionsList`.map((releaseNote, index) => (
        <VersionBox key={index} alternate={index % 2 !== 0}>
          <h3>{releaseNote.version}</h3>
          <ul>
            { releaseNote.notes.map((note, index) => (
              <li key={index}>{note}</li>
            ))}
          </ul>
        </VersionBox>
      ))}
    </Container>
  )
}

export default ReleaseNotesPage