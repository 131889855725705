import { useEffect, useState } from 'react';
import { UAParser } from 'ua-parser-js';

const useDeviceDetect = () => {
    const [device, setDevice] = useState(null);
    
    useEffect(() => {
        const { device: parsedDevice } = UAParser()
        const { type, vendor } = parsedDevice

        if (vendor === 'Apple') {
            if (type === 'mobile') {
                setDevice('iOS');
            }
            if (type === 'tablet') {
                setDevice('iPad');
            }
        }
    }, [])

    return { device, setDevice }
}

export default useDeviceDetect