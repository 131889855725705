import styled from "@emotion/styled";
import { colors, media } from "../../BaseStyles";
import SettingsLightImage from "../../images/settingsLight.png"
import SettingsDarkImage from "../../images/settingsDark.png"

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  width: 100vw;
  flex-grow: 1;
`

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;

  width: 100vw;
`

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100vw;
`

export const SearchNotes = styled.div`
  color: ${colors.secondary};
  font-style: italic;

  a {
    color: ${colors.secondary};
    text-decoration: underline;
  }
`

export const Discover = styled.button`
  margin-bottom: 20px;
  padding: 0 !important;

  color: ${colors.primary};
  background: none !important;
  border: none;

  text-decoration: underline;
  cursor: pointer;

  ${media.darkMode} {
    color: ${colors.primaryInverted};
  }
`;

export const Settings = styled.img`
  width: 25px;
  height: 25px;
  padding: 20px;

  object-fit: contain;
  content: url(${SettingsLightImage});

  ${media.darkMode} {
    content: url(${SettingsDarkImage});
  }
`
export const SearchTopFix = styled.div`
  
`