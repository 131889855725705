import styled from "@emotion/styled";
import LogoLightImage from "./images/logoLight.png"
import LogoDarkImage from "./images/logoDark.png"

export const widthBreakpoints = {
  small: 559,
  medium: 560,
  large: 1200,
}

export const heightBreakpoints = {
  small: 419,
  medium: 420,
  large: 700,
}

export const media = {
  smallWidth: `@media (max-width: ${widthBreakpoints.small}px)`,
  mediumWidth: `@media (min-width: ${widthBreakpoints.medium}px)`,
  largeWidth: `@media (min-width: ${widthBreakpoints.large}px)`,

  smallHeight: `@media (max-height: ${heightBreakpoints.small}px)`,
  mediumHeight: `@media (min-height: ${heightBreakpoints.medium}px)`,
  largeHeight: `@media (min-height: ${heightBreakpoints.large}px)`,

  darkMode: "@media (prefers-color-scheme: dark)",
}

export const colors = {
  primary: "#000000",
  primaryInverted: "#ffffff",
  secondary: "#9d9d9d"
};

export const Logo = styled.img`
  width: 100vw;
  height: 150px;

  object-fit: contain;
  content: url(${LogoLightImage});

  ${media.smallWidth} {
    height: 70px;
  }

  ${media.smallHeight} {
    height: 70px;
  }

  ${media.darkMode} {
    content: url(${LogoDarkImage});
  }
`

export const HorizontalLine = styled.div`
  width: 100vw;
  height: 1px;

  background: #000000;

  ${media.darkMode} {
    background: #ffffff;
  }
`