import { useEffect, useState } from 'react';

const monoApp = window?.webkit?.messageHandlers?.monoApp

export const insideMonoApp = () => {
  if (typeof monoInsideApp === "function") {
    // eslint-disable-next-line no-undef
    return monoInsideApp()
  } else {
    return false
  }
}

export const monoAppVersion = () => {
  if (typeof monoGetVersion === "function") {
    // eslint-disable-next-line no-undef
    return monoGetVersion()
  } else {
    return ""
  }
}

export const useMonoAppSettingsVars = () => {
  const defaultSettingsVars = {
    blockAds: false,
    blockImages: false,
    keepScreenOn: false,
    themeSelection: 0,
    halfMonochromatic: false,
    leftHanded: false,
    autoFavorites: false,
    searchEngineSelection: 0,
    iapStatus: "trialNeverStarted"
  }
  const [settingsVars, setSettingsVars] = useState(defaultSettingsVars);
  useEffect(() => monoApp?.postMessage({ function: "loadSettingsVars" }), [])
  window.setMonoAppSettingsVars = setSettingsVars
  return settingsVars;
}

export const saveMonoAppSettingsVars = (settingsVars) => {
  monoApp?.postMessage({ function: "saveSettingsVars", ...settingsVars })
}

export const openMonoAppSettings = () => {
  monoApp?.postMessage({ function: "openSettings" })
}


export const loadUrlOrSearchViaMonoApp = (text) => {
  monoApp?.postMessage({ function: "loadUrlOrSearch", text: text })
}

export const requestSuggestionsViaMonoApp = (text) => {
  monoApp?.postMessage({ function: "requestSuggestions", text: text })
}

/**
 * Listen for calls from Mono App and returns a list of Suggestion objects
 * 
 * @returns {Array<{icon: String, title: String, link: String}>}
 */
export const useMonoAppSuggestions = () => {
  /** @type {Array<{icon: String, title: String, url: String}>} */ const defaultSuggestions = []
  const [suggestions, setSuggestions] = useState(defaultSuggestions);
  window.setMonoSuggestions = setSuggestions
  return suggestions;
}
