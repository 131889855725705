import { useState, useEffect } from "react"
import Search from "./Search/Search"
import { Container, Discover, Header, SearchNotes, Section, Settings } from "./styled"
import { insideMonoApp, useMonoAppSettingsVars, openMonoAppSettings } from "../../utils/MonoAppBridge"
import useWindowDimensions, { BreakPoint } from "../../utils/useWindowDimensions"
import { Trans, useTranslation } from 'react-i18next'
import SuggestionsDropDown from "./SuggestionsDropDown/SuggestionsDropDown"
import { Logo } from "../../BaseStyles"
import PanelsGroup from "../../components/PanelsGroup/PanelGroup"

const SearchEngines = [
  {
    name: "DuckDuckGo", 
    link: "https://start.duckduckgo.com"
  },
  {
    name: "Google", 
    link: "https://www.google.com"
  },
  {
    name: "Yahoo", 
    link: "https://yahoo.com"
  },
  {
    name: "Bing", 
    link: "https://www.bing.com"
  },
  {
    name: "Ecosia", 
    link: "https://www.ecosia.org"
  },
  {
    name: "Baidu百度", 
    link: "https://www.baidu.com"
  },
]

const HomePageMonoApp = () => {
  const { t } = useTranslation()
  const { searchEngineSelection } = useMonoAppSettingsVars()
  const { heightBp } = useWindowDimensions()
  const [showSuggestions, setShowSuggestions] = useState(false)
  const [showPanelsGroup, setShowPanelGroup] = useState(false)
  const [searchReset, setSearchReset] = useState(false)
  const [smallHeightBp, setSmallHeightBp] = useState(heightBp === BreakPoint.small)

  useEffect(() => {
    const latestSmallHeightBp = heightBp === BreakPoint.small
    setSmallHeightBp(latestSmallHeightBp)
  }, [heightBp])

  if (!insideMonoApp()) {
    return null
  }

  const searchEngine = SearchEngines[searchEngineSelection]

  const discoverClicked = () => {
    setShowPanelGroup(true)
  }

  const searchOnFocusChange = (focused) => {
    if (focused) {
      setShowPanelGroup(false)
      setShowSuggestions(true)
    }
  }

  const closeSuggestions = () => {
    setShowSuggestions(false)
    setSearchReset(!searchReset)
  }

  return (
    <Container>
        { !showSuggestions &&
          <Header>
            <Settings onClick={openMonoAppSettings} />
          </Header>
        }
        <Section>
            <Logo />
            <h2>{t`insideMonoAppMainPanel.title`}</h2> 
          <Search onFocusChange={searchOnFocusChange} onClose={closeSuggestions} reset={searchReset} />
          { showSuggestions ?
            <SuggestionsDropDown onClose={closeSuggestions} />
          : searchEngine &&
            <SearchNotes>
              <Trans i18nKey="insideMonoAppMainPanel.poweredBy">
                Powered by <a href={searchEngine.link}>{{searchEngine}}</a>
              </Trans>
            </SearchNotes>
          }
        </Section>
        { showPanelsGroup ?
          <PanelsGroup />
        : !showSuggestions && !smallHeightBp &&
          <Discover onClick={discoverClicked}>
            <h1>{t`insideMonoAppMainPanel.features`}</h1>
          </Discover>
        }
    </Container>
  )
}

export default HomePageMonoApp