import { Container, AppStoreBadge, MainPanel } from "./styled"
import PanelsGroup from "../../components/PanelsGroup/PanelGroup"
import { useTranslation } from "react-i18next";
import { Logo } from "../../BaseStyles";

const HomePageBrowser = () => {
  const { t } = useTranslation()

  const appStoreBadgeClicked = () => {
    window.open(t`appStoreLink`, '_blank');
  }

  return (
    <Container>
      <MainPanel>
        <Logo />
        <h1>{t`otherBrowserMainPanel.title`}</h1>
        <h2>{t`otherBrowserMainPanel.subtitle`}</h2>
        <AppStoreBadge lang={t`lang`} onClick={appStoreBadgeClicked} />
      </MainPanel>
      <PanelsGroup id="homepagePanelsGroup" />
    </Container>
  )
}

export default HomePageBrowser;