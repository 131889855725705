import { t } from "i18next"
import { useMonoAppSuggestions, loadUrlOrSearchViaMonoApp } from "../../../utils/MonoAppBridge"
import useWindowDimensions, { BreakPoint } from "../../../utils/useWindowDimensions"
import { CloseButton, Container, HintImage, HintText, Row, Suggestion, SuggestionsList } from "./styles"
import SearchLightImage from "../../../images/searchLight.png"
import SearchDarkImage from "../../../images/searchDark.png"
import GlobeLightImage from "../../../images/globeLight.png"
import GlobeDarkImage from "../../../images/globeDark.png"
import useColorScheme from "../../../utils/useColorScheme"
import { HorizontalLine } from "../../../BaseStyles"

const SuggestionsDropDown = ({ onClose }) => {
  const suggestions = useMonoAppSuggestions()
  const { darkMode } = useColorScheme()
  const { heightBp } = useWindowDimensions()

  const getImage = ({ icon, url }) => {
    if (!url) {
      return darkMode ? SearchDarkImage : SearchLightImage
    } else if (!icon) {
      return darkMode ? GlobeDarkImage : GlobeLightImage
    } else {
      return icon
    }
  }

  const suggestionClicked = ({ title, url }) => {
    if (url) {
      loadUrlOrSearchViaMonoApp(url)
    } else {
      loadUrlOrSearchViaMonoApp(title)
    }
  }

  return (
    <Container>
      <SuggestionsList>
        { suggestions.map((suggestion, index) => {
          return (
            <Row key={index}>
              <HorizontalLine />
              <Suggestion base64={suggestion.icon} onClick={() => suggestionClicked(suggestion)}>
                <img src={getImage(suggestion)} alt="" />
                <p>{t(suggestion.title)}</p>
              </Suggestion>
            </Row>
          )
        })}
      </SuggestionsList>
      { suggestions.length < 1 && heightBp !== BreakPoint.small &&
        <>
          <HintImage />
          <HintText>{t`insideMonoAppMainPanel.suggestionsDropDown.searchHint`}</HintText>
        </>
      }
      <CloseButton onClick={onClose}>{t`insideMonoAppMainPanel.suggestionsDropDown.close`}</CloseButton>
    </Container>
  )
}

export default SuggestionsDropDown