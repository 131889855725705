import { useEffect, useRef, useState } from "react"
import Button from "../../../components/Button/Button"
import TextField from "../../../components/TextField/TextField"
import { Container } from "./styled"
import { useTranslation } from 'react-i18next'
import { loadUrlOrSearchViaMonoApp, requestSuggestionsViaMonoApp } from "../../../utils/MonoAppBridge"

const Search = ({ onFocusChange, onClose, reset }) => {
  const [searchText, setSearchText] = useState("");
  const { t } = useTranslation();
  const textFieldRef = useRef(null)

  const textFieldChanged = (event) => {
    const text = event.target.value
    setSearchText(text)
    requestSuggestionsViaMonoApp(text)
  }

  const buttonClicked = () => {
    if (searchText.trim().length > 0) {
      loadUrlOrSearchViaMonoApp(searchText)
    } else {
      onClose()
    }
  }

  useEffect(() => {
    setSearchText("")
    textFieldRef.current?.blur()
  }, [reset])

  return (
    <Container>
      <TextField
        inputRef={textFieldRef}
        placeholder={t`insideMonoAppMainPanel.search.hint`} 
        text={searchText}
        onTextChange={textFieldChanged} 
        onFocusChange={onFocusChange}
        onEnterKeyPressed={buttonClicked} />
      <Button onClick={buttonClicked}>{t`insideMonoAppMainPanel.search.button`}</Button>
    </Container>
  )
}

export default Search