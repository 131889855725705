import styled from "@emotion/styled";
import { colors, media } from "../../BaseStyles";

export const MyInput = styled.input`
  box-sizing: border-box;
  width: 100%;
  max-width: 500px;
  height: 40px;

  color: ${colors.primary};
  background: #f3f3f3;
  border: 1px solid ${colors.primary};
  border-radius: 0;

  font-size: 20px;

  ::placeholder {
    color: ${colors.secondary};
  }

  ${media.darkMode} {
    color: ${colors.primaryInverted};
    background: #333333;
    border: 1px solid ${colors.primaryInverted};
  }
`