import styled from "@emotion/styled";
import { colors } from "../../BaseStyles";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    
    min-height: 600px;
`

export const VersionBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100vw;
    padding: 20px;

    background: ${(props) => (props.alternate ? colors.primary : colors.primaryInverted)};
    color: ${(props) => (props.alternate ? colors.primaryInverted : colors.primary)};

    li { 
        margin: 5px 0 5px 0;
    }
`