import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-xhr-backend'
import LanguageDetector from "i18next-browser-languagedetector"

i18n
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // connect with React
  .use(initReactI18next)
  // Browser language detector
  .use(LanguageDetector)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'en',
    supportedLngs: ['en', 'it'],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    returnObjects: true,
    detection: {
      // check localStorage first, browser's language later
      order: ["navigator"],
      // localStorage key name
      lookupLocalStorage: 'i18nextLng',
      // only detect languages that are in the whitelist
      checkWhitelist: true
    }
  });
export default i18n;